import React from "react"
import Layout from "@app-components/layout/"
import Feature from "@app-components/ui/feature/"
import Grid from "@app-components/ui/grid/"
import Header from "@app-components/ui/header/side_by_side/"
import HEADERS from "@data/headers/"
import HeaderImage from "@page-components/products/dcp-manager/media/header/"
import DownloadImage from "@page-components/products/dcp-manager/media/download/"
import UIImage from "@page-components/products/dcp-manager/media/ui/"
import OutputImage from "@page-components/products/dcp-manager/media/output/"
import DCPImage from "@page-components/products/dcp-manager/media/dcp/"
import HardwareImage from "@page-components/products/dcp-manager/media/hardware/"

const DCPManager = () => (
  <Layout
    getStartedButtons={["demo", "contact-sales"]}
    page="products/dcp-manager"
    getStartedText="Sign up today and become your festival's print traffic hero.">
    <Header
      title="CineSend CS3 &amp; CS6"
      text="Connect a DCP Manager to your CineSend Festivals account for a complete end-to-end print traffic solution for festivals that exhibit DCP."
      image={<HeaderImage />}
      secondaryNavigation={HEADERS.dcp_manager}
      buttons={[
        {
          text: <div className="red">Purchase</div>,
          className: "web_white",
          type: "link",
          link: {
            to: "/products/dcp-manager/pricing",
            type: "internal"
          }
        },
        {
          text: "Schedule a Demo",
          className: "web_white_outline ",
          type: "link",
          link: {
            to: "/demo",
            type: "internal"
          }
        }
      ]}
    />
    <Feature
      title="Automatically Download DCPs"
      text="Download content at speeds up to 1Gbps with IBM Aspera’s file acceleration protocol built end-to-end into the CineSend Festivals platform."
      icon="download-white"
      image={<DownloadImage />}
    />
    <Feature
      reverse
      title="Web-based User Interface"
      text="The CineSend DCP Manager is controlled entirely from an intuitive web interface within the CineSend for Festivals portal, making it simple enough for non-technical staff to handle DCPs."
      icon="screen-white"
      image={<UIImage />}
    />
    <Feature
      title="Output CRU and USB3 Drives"
      text="Eliminate the need to “traffic” films between venues by creating compilation drives. CS appliances format hard drives correctly to EXT2/3 DCI spec before copying hundreds of films simultaneously in multiple drive bays."
      icon="usb-white"
      image={<OutputImage />}
    />
    <Feature
      reverse
      title="DCP Aware"
      text="DCP Managers validate SMPTE and InterOp DCPs for playability, flag compliance issues, and fix common XML schema errors. It even has full support for Version File (VF) DCPs."
      icon="file-white"
      image={<DCPImage />}
    />
    <Feature
      title="Robust Hardware"
      text="Systems provide up to 50TB of local storage in a RAID configuration built with enterprise-grade hardware that’s secure and built to last - the DCP Manager platform is built to meet strict MPAA security standards for content protection."
      icon="hardware-white"
      image={<HardwareImage />}
    />
    <Grid
      columnWidth={4}
      items={[
        {
          title: "Print Drive Labels",
          text:
            "Instantly generate printable drive labels that list CPLs, runtimes, and other tech specs for DCP’s copied to a CRU or USB drives. Labels are formatted to print on Zebra label printers.",
          _id: 1,
          icon: "print-white"
        },
        {
          title: "Limit Download Speeds",
          text: `If you need to share your internet connection with other business systems or general office use, set up optional business hours/ time-of-day bandwidth caps to restrict CSX download speeds.`,
          _id: 2,
          icon: "network-white"
        },
        {
          title: "Easy Setup",
          text:
            "Installation is as simple as connecting the unit to power, and the internet. If needed, custom network settings can be configured through a step-by-step setup wizard with any device with wi-fi.",
          _id: 3,
          icon: "settings-white"
        },
        {
          title: "Integrated Firewall",
          text:
            "The CS3 & CS6 can be plugged directly into the internet. The device and it’s integrated storage are protected behind a robust firewall that’s pre-configured out of the box.",
          _id: 4,
          icon: "security-white"
        },
        {
          title: "Network Ingest Option",
          text:
            "The CS3 & CS6 can be connected to a TMS or cinema server to allow network ingest, perfect for content inspection, or if the device is situated in a screening venue.",
          _id: 5,
          icon: "download-white"
        },
        {
          title: "Link Multiple Devices",
          text:
            "Optionally connect multiple DCP Managers or CSX devices to a single CineSend Festivals account to push content to venues electronically and avoid physical drives altogether",
          _id: 6,
          icon: "arrows-white"
        }
      ]}
    />
  </Layout>
)

DCPManager.propTypes = {}

export default DCPManager
